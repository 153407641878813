<template>
  <div>
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="8" md="8">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="'/assets-invoice'"
                style="text-decoration: none"
              >
                <v-btn rounded color="#05a2fa">
                  <v-icon color="white"> mdi-arrow-left </v-icon>
                </v-btn>
              </router-link>
            </v-row>
          </v-col>

          <v-col cols="14" sm="8" md="4">
            <v-row dense justify="end" class="mb-5">
              <div>
                <v-btn rounded color="#05a2fa" dark @click="dialog = true">
                  <v-icon color="white"> mdi-plus-circle </v-icon>
                  <v-card-text> Add a New Stock </v-card-text>
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="14" sm="8" md="8" class="itemsLayout">
            <v-row dense>
              <v-col cols="3" class="pb-3">
                <v-card height="200" width="150" @click="dialog = true">
                  <v-card-text class="text-center">
                    <v-icon
                      color="#05a2fa"
                      style="font-size: 70px"
                      class="mt-11"
                    >
                      mdi-plus
                    </v-icon>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                v-for="card in newItems"
                :key="card.assetStockId"
                :cols="3"
                class="pb-3"
              >
                <router-link
                  v-bind:to="{
                    path: '/assets-invoice/assets-stock/' + card.assetStockId,
                  }"
                  style="text-decoration: none"
                >
                  <v-card height="200" width="150" style="cursor: pointer">
                    <v-img
                      :src="`https://cdn.vuetifyjs.com/images/cards/house.jpg`"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      width="150"
                      height="100"
                    >
                      <v-card-title style="height: 40px">
                        <div class="headerClass">
                          <v-icon
                            style="font-size: 20px; color: #05a2fa"
                            color="#ffffffff"
                            class="mb-1"
                          >
                            mdi-city
                          </v-icon>

                          Stock Id : {{ card.assetStockId }}
                        </div>
                      </v-card-title>
                    </v-img>

                    <v-card-text>
                      Quantity: {{ card.quantity }} <br />
                      cost: $.{{ card.cost }} <br />
                    </v-card-text>
                  </v-card>
                </router-link>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="4" md="4">
            <v-card>
              <div style="float: right; margin-top: 10px; margin-right: 10px">
                <v-icon
                  color="#05a2fa"
                  @click="editItem()"
                  style="margin-right: 5px"
                >
                  mdi-pencil-outline
                </v-icon>
                <v-icon color="#FF0000" @click="deleteItem()">
                  mdi-trash-can-outline
                </v-icon>
              </div>

              <!-- <v-card-title>
                Stock Invoice Id : {{ stockInvoiceInfo.invoiceId }}
              </v-card-title> -->
              <v-card-title style="font-size: 20px">
                Invoice No : {{ stockInvoiceInfo.invoiceNo }}
              </v-card-title>
              <v-divider class="mx-4"></v-divider>
              <v-card-text>
                Date : {{ stockInvoiceInfo.invoiceDate | momentLocal }}<br />
                Reference :{{ stockInvoiceInfo.reference }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-col cols="14" sm="8" md="8" style="margin-top: -25px">
        <v-pagination
          v-model="page"
          :length="pageCount"
          @click="changePage"
          circle
          color="#05a2fa"
        ></v-pagination>
      </v-col>
      <v-container @click="dialogTrash = true" style="cursor: pointer">
        <v-row>
          <v-col cols="14" sm="8" md="8">
            <v-card>
              <v-card-text class="text-center" style="font-size: 15px">
                <v-icon color="#05a2fa" style="font-size: 25px">
                  mdi-delete-empty
                </v-icon>
                Trash
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Add New Stock</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="ProductsForm">
                <v-row>
                  <!-- <v-col cols="12">
                    <v-select
                      v-model="editedItem.invoiceId"
                      :items="stockInvoiceInfo"
                      item-text="invoiceId"
                      item-value="invoiceId"
                      label="Stock Invoice Id"
                    />
                  </v-col> -->
                  <v-col cols="12">
                    <v-select
                      v-model="editedItem.assetTypeId"
                      :items="assetTypes"
                      item-text="assetTypeName"
                      item-value="assetTypeId"
                      label="Asset Type Id"
                      outlined
                      clearable
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      v-model="editedItem.locationId"
                      :items="locations"
                      item-text="locationName"
                      item-value="locationId"
                      label="Location Id"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      type="number"
                      class="inputNumber"
                      v-model="editedItem.cost"
                      label="Cost"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      type="number"
                      class="inputNumber"
                      v-model="editedItem.quantity"
                      label="Quantity"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="validate"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogEdit" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Stock Invoice </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="categoriesForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedStockInvoiceInfo.newDate"
                      @click="dateModalActive()"
                      label="Date"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedStockInvoiceInfo.reference"
                      label="Reference"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialogEdit = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveEdit"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to delete this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="trashItem"> OK </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogTrash" max-width="1200">
        <v-card class="pa-10">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>
          <v-data-table :headers="headers" :items="trashed" :search="search">
            <template v-slot:[`item.restore`]="{ item }">
              <v-icon color="#05a2fa" @click="restoreItem(item)">
                mdi-restore
              </v-icon>
            </template>
            <template v-slot:[`item.delete`]="{ item }">
              <v-icon color="#FF0000" @click="deleteItem(item)">
                mdi-trash-can-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogRestore" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to restore this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialogRestore = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="restoreItemConfm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dateModal" max-width="500px">
        <v-card>
          <v-row justify="center">
            <v-date-picker v-model="date" color="#05a2fa"></v-date-picker>

            <v-time-picker
              v-model="time"
              ampm-in-title
              format="ampm"
              color="#05a2fa"
            ></v-time-picker>
          </v-row>
        </v-card>

        <v-card-actions class="pt-5">
          <v-row>
            <v-btn
              depressed
              block
              full-width
              color="white"
              @click="closeDateModal"
            >
              <v-icon color="red"> mdi-close-circle </v-icon>
            </v-btn>
          </v-row>

          <v-row>
            <v-btn depressed block full-width color="white" @click="addDate">
              <v-icon color="blue"> mdi-check-circle </v-icon>
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-dialog>
    </v-card-text>
  </div>
</template>

<style>
.itemsLayout {
  height: 450px;
  overflow: hidden;
  overflow-y: auto;
}
.inputNumber input[type="number"] {
  -moz-appearance: textfield;
}
.inputNumber input::-webkit-outer-spin-button,
.inputNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>

<script>
var moment = require("moment");
export default {
  data() {
    return {
      dialog: false,
      dialogEdit: false,
      dateModal: false,
      dialogDelete: false,
      dialogRestore: false,
      dialogTrash: false,
      search: "",
      headers: [
        {
          text: "stockInvoiceProductId",
          value: "stockInvoiceProductId",
        },

        { text: "quantity", value: "quantity" },
        { text: "productId", value: "productId" },
        { text: "invoiceId", value: "invoiceId" },

        { text: "", value: "restore" },
        { text: "", value: "delete" },
      ],
      products: [],
      assetStocks: [],
      stockInvoiceInfo: {},
      stockInvoices: [],
      editedStockInvoiceInfo: {
        categoryName: "",
        description: "",
      },

      categories: [],
      locations: [],
      assetTypes: [],
      status: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      stockStatus: [
        { text: "Yes", value: false },
        { text: "No", value: true },
      ],
      editedIndex: -1,

      editedItem: {
        invoiceId: "",
        assetTypeId: "",
        cost: "",
        quantity: "",
        locationId: "",
      },
      defaultItem: {
        invoiceId: "",
        assetTypeId: "",
        cost: "",
        quantity: "",
        locationId: "",
      },
      trashed: [],
      page: 1,
      pageCount: null,
      start: 0,
      end: 7,
      newItems: [],
      newDate: "",
      date: null,

      time: "",
      invoiceId: "",
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add a New Product" : "Edit Product";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page: function () {
      this.changePage();
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("MMMM Do YYYY - h:mm A");
    },

    momentLocal: function (date) {
      return moment.utc(date).local().format("MMMM Do YYYY - h:mm A");
    },
  },

  created() {
    this.getDataFromApi();
  },

  methods: {
    getDataFromApi() {
      var currentUrl = window.location.pathname;
      let vars = currentUrl.split("/");
      vars.reverse();
      let url = vars[0];
      url = parseInt(url);
      this.invoiceId = url;
      this.editedItem.invoiceId = url;

      let clubId = 1;

      this.$http
        .get(`/AssetStock/GetAllByInvoiceId?InvoiceId=${url}`)
        .then((response) => {
          this.assetStocks = response.data.data;
          this.changePage();
        });
      // this.$http.get("/ProductStock/Invoice").then((response) => {
      //   this.stockInvoices = response.data.data;
      // });
      this.$http.get("/AssetType").then((response) => {
        this.assetTypes = response.data;
        console.log(this.assetTypes);
      });
      this.$http.get("/Location").then((response) => {
        this.locations = response.data.data;
      });

      this.$http.get(`AssetInvoice/${url}`).then((response) => {
        this.stockInvoiceInfo = response.data.data;
      });

      // this.$http.get(`/ProductStock/GetTrashed/${url}`).then((response) => {
      //   this.trashed = response.data.data;
      // });
    },
    updateData() {
      this.page = 1;
      this.changePage();
    },
    changePage() {
      var count = this.assetStocks.length;
      var float = count / this.end;
      var int = parseInt(count / this.end);
      if (float > int) {
        this.pageCount = int + 1;
      } else {
        this.pageCount = int;
      }
      var diff = this.page - 1;
      var newStart = this.start + this.end * diff;
      var newEnd = this.end + this.end * diff;

      const items = this.assetStocks.slice(newStart, newEnd);
      this.newItems = items;
    },
    dateModalActive() {
      var local = moment
        .utc(this.stockInvoiceInfo.invoiceDate)
        .local()
        .format();

      this.date = moment(local).format("YYYY-MM-DD");
      this.time = moment(local).format("HH:mm");

      this.dateModal = true;
    },
    addDate() {
      var date = this.date.concat("T").concat(this.time);
      var utcDate = moment.utc(moment(date).utc()).format();

      this.editedStockInvoiceInfo.invoiceDate = utcDate;

      this.editedStockInvoiceInfo.newDate = moment(
        this.editedStockInvoiceInfo.invoiceDate
      ).format("MMMM Do YYYY, h:mm: A");

      this.dateModal = false;
    },
    closeDateModal() {
      // this.editedStockInvoiceInfo.newDate = moment
      //   .utc(this.stockInvoiceInfo.invoiceDate)
      //   .local()
      //   .format("MMMM Do YYYY, h:mm A");

      this.dateModal = false;
    },
    editItem() {
      this.editedStockInvoiceInfo = Object.assign({}, this.stockInvoiceInfo);

      this.editedStockInvoiceInfo.newDate = moment
        .utc(this.stockInvoiceInfo.invoiceDate)
        .local()
        .format("MMMM Do YYYY, h:mm A");

      this.dialogEdit = true;
    },

    deleteItem() {
      this.dialogDelete = true;
    },
    trashItem() {
      this.$http
        .post(`/AssetInvoice/Trash?Id=${this.stockInvoiceInfo.invoiceId}`)
        .then((response) => {
          if (response.data.success === true) {
            this.$toast.success("Deleted successfully.", "Success", {
              position: "topRight",
            });
            this.$router.push("/assets-invoice/");
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(" Deleting Process Failed", "Error", {
              position: "topRight",
            });
          }
        });
    },
    restoreItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogRestore = true;
    },
    restoreItemConfm() {
      this.$http
        .post("/ProductStock/Restore", this.editedItem)
        .then((response) => {
          if (response.data.success === true) {
            this.trashed.shift(response.data.data);
            this.products.unshift(response.data.data);
            this.updateData();
            this.$toast.success("Restore successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(" Restore Process Failed", "Error", {
              position: "topRight",
            });
          }
        });

      this.dialogRestore = false;
    },
    deleteItemConfirm() {
      var Id = this.editedItem.productId;
      this.$http
        .delete("/Product/" + Id, null, {
          params: { Id: this.editedItem.id },
        })
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success(
              "Product deleted successfully." + response.status,
              "Success",
              {
                position: "topRight",
              }
            );
          }
          this.products.shift(response.data.data);
        })

        .catch((error) => {
          if (error.response.status === 404) {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
      //this.users.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.invoiceId = this.invoiceId;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    validate() {
      if (
        this.editedItem.assetTypeId &&
        this.editedItem.locationId &&
        this.editedItem.cost &&
        this.editedItem.quantity
      ) {
        this.save();
      } else {
        this.$toast.error("Please Fill the Form", "Error", {
          position: "topRight",
        });
      }
    },
    save() {
      this.$http
        .post("/AssetStock", this.editedItem)
        .then((response) => {
          if (response.data.isSuccess === true) {
            this.assetStocks.unshift(response.data.outputObject);
            this.updateData();

            this.$toast.success(
              "Product Stock added successfully.",
              "Success",
              {
                position: "topRight",
              }
            );
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error("Adding Process Failed", "Error", {
              position: "topRight",
            });
          }
        });

      this.close();
    },

    saveEdit() {
      const data = this.editedStockInvoiceInfo;
      var Id = this.stockInvoiceInfo.categoryId;
      this.$http
        .put("/ProductCategory/" + Id, data)
        .then((response) => {
          if (response.data.success === true) {
            this.getDataFromApi();
            this.$toast.success("Category updated successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(" Updating Process Failed", "Error", {
              position: "topRight",
            });
          }
        });
      this.dialogEdit = false;
    },

    getStatusColor(data) {
      switch (data) {
        case "true":
          return "success";
        case "false":
          return "error";
        default:
          return "";
      }
    },
    getSaleStatus(sale) {
      switch (sale) {
        case 1:
          return "";
        default:
          return "d-none";
      }
    },
  },
};
</script>
